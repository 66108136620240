@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
  font-family: 'Colour Sans Bold';
  src: local('ColourSans-Bold'), url(./assets/fonts/ColourSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Colour Sans Light';
  src: local('ColourSans-Light'), url(./assets/fonts/ColourSans-Light.ttf) format('truetype');
}
