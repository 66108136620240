@import '../_variables';

.banner-image-wrapper {
  flex-basis: auto !important;
  max-height: 160px;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
  }
}
.service-page {
  &__container {
    @media ($medium-up) {
      min-width: 840px;
    }
  }
}
