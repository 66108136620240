// Title mixins
// -----------------------------------------------------------------------------

//
// 1. Prevents text from borken into multiple lines
//

@mixin title-set {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  .heading6 {
    margin-bottom: 0;
  }

  .mat-button {
    margin-right: -16px;
  }

  // 1
  .link {
    white-space: nowrap;
  }
}

@mixin title-set-showing {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;

  .subtitle-1 {
    margin-bottom: 0;
  }

  .caption {
    margin-left: 8px;
  }
}
