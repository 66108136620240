// Work Smarter
// -----------------------------------------------------------------------------
//
// 1. `20px` gutter between tiles, as designed.
//
@import '~@hackandcraft/css-framework/scss/settings';
@import '~@hackandcraft/css-framework/scss/main.scss';
@import '../_variables';
@import '../mixins/link';

// Scaffolding
$_assets-path: '/assets/';
$_img-path: $_assets-path + 'img/';

.category-page {
  display: flex;
  flex-direction: column !important;
  position: relative;
  height: 100%;
  padding-top: 12px;
  overflow-x: hidden;
  @media ($large-up) {
    width: calc(100% - 230px);
    margin-left: 230px;
  }

  .home-link {
    color: $c-primary-main;
    cursor: pointer;
  }

  &__banner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: fit-content;
    margin: 24px 0 40px !important;

    h4,
    p {
      color: white;
    }
  }

  &__container {
    margin-left: 0 !important;
    column-gap: 20px;
  }

  &__item {
    padding: 0 !important;
    width: 100%;

    &.MuiGrid-root {
      @media ($small-up) and ($medium-down) {
        max-width: calc(50% - 10px);
      }

      @media ($medium-up) {
        max-width: calc(33.333333% - 14px);
      }
    }

    &:last-child {
      .category-page-item--text {
        @media ($small-down) {
          border-bottom: none;
        }
      }
    }

    &:nth-child(2n+1):nth-last-child(-n+2),
    &:nth-child(2n+1):nth-last-child(-n+2) ~ & {
      .category-page-item--text {
        @media ($small-up) and ($medium-down) {
          border-bottom: none;
        }
      }
    }

    &:nth-child(3n+1):nth-last-child(-n+3),
    &:nth-child(3n+1):nth-last-child(-n+3) ~ & {
      .category-page-item--text {
        @media ($medium-up) {
          border-bottom: none;
        }
      }
    }
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    max-width: 230px !important;
  }

  .MuiList-root {
    margin-top: 12px;
  }
}

.category-page-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 12px;

  // Work smarter item as link
  &--link {
    @include link-active;
  }

  &__media {
    margin-right: 20px;
  }

  &--detail {
    height: 60px;
    width: 100%;
    @include text-truncate-multiline(45px, 3);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--text {
    border-bottom: 1px solid $divider-br-c;
    padding-bottom: 10px;
    width: calc(100% - 112px - 20px);
  }
}
