@import '../mixins/title';

.top-searches {
  padding: 12px 0 32px;
  
  &__title {
    @include title-set;
  }

  &__chip {
    background-color: rgba(224,224,224,1);
  }
  // &__term {
  //   //
  // }
}
