@import '~@hackandcraft/css-framework/scss/settings';
@import '~@hackandcraft/css-framework/scss/main.scss';

.insight-card{
  &__description{
    width:100%;
    @include text-truncate-multiline(30px, 2);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}