@import '~@hackandcraft/css-framework/scss/settings';
@import '~@hackandcraft/css-framework/scss/main.scss';
@import '../mixins/title';
@import '../mixins/pseudo-gradient';
@import '../mixins/link';
@import '../variables';
// Recent items
// -----------------------------------------------------------------------------
//
// 1. We can't use `:nth-last-child(-n+3)`, as suggested in
//    https://stackoverflow.com/questions/14268156/select-the-last-3-child-elements ,
//    as we can't predict the total number of items.
//    Using a pseudo-element instead, to cover the border.
// 2. When a parent element has a gradient, we can't use a pseudo-element to
//    cover the border, so we just remove it.
//

//
// Recent items
//
.recent-items {
  padding: 12px 0px 32px;

  &__title {
    @include title-set;
  }

  &__content:last-child {
    .recent-item .recent-item__content {
      @media ($small-down) {
        border-bottom: none;
      }
    }
  }
  
  &__content:nth-child(2n+1):nth-last-child(-n+2),
  &__content:nth-child(2n+1):nth-last-child(-n+2) ~ &__content {
    .recent-item .recent-item__content {
      @media ($small-up) and ($large-down) {
        border-bottom: none;
      }
    }
  }
  
  &__content:nth-child(3n+1):nth-last-child(-n+3),
  &__content:nth-child(3n+1):nth-last-child(-n+3) ~ &__content {
    .recent-item .recent-item__content {
      @media ($large-up) {
        border-bottom: none;
      }
    }
  }
}

.u-text-truncate-multi {
  @include text-truncate-multiline(30px, 2);
  overflow: hidden;
  text-overflow: ellipsis;
}

//
// Recent item
//
.recent-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 12px;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: calc(100% - 60px);
    min-height: 132px;
    padding: 16px 0px;
    border-bottom: 1px solid $divider-br-c;
  }

  &__media {
    margin-right: 20px;
  }

  // Recent item as link
  &--link {
    @include link-active;
  }
}
