@import '~@hackandcraft/css-framework/scss/settings';
@import '~@hackandcraft/css-framework/scss/main.scss';
@import '../mixins/title';
@import '../mixins/pseudo-gradient';
@import '../mixins/link';
@import '../variables';

:host {
  position: relative;
}

.search-dropdown {
  position: absolute;
  top: -56px;
  left: 0;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: $c-light;
  z-index: 9;
  overflow: hidden;
}

.search-dropdown-subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 12px 17px 0;
  background-color: $c-light;

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 12px;
  }

  &__action.link {
    color: $c-primary-main;
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    @media ($medium-up) {
      padding-right: 20px;
    }
  }
}

.search-dropdown-content {
  background-color: $c-light;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-dropdown-no-results {
  background-color: $c-light;
  padding-left: 12px;
  padding-bottom: 12px;

  &__title.subtitle-1 {
    margin-top: 0;
    margin-bottom: 12px;
    color: $c-dark-high-emphasis;
  }

  &__text.body-2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.search-dropdown-need-help {
  padding: 16px 0px 12px 12px;
  background-color: $c-light;

  &__text.body-2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Search results panel
// -----------------------------------------------------------------------------

// Container
.search-results-container {
  position: relative;
}

// Page
// .search-results {
//   //
// }
.progress-spinner-wrapper {
  position: absolute;
  z-index: 100;
  left: calc(50% - #{$progress-spinner-wd / 2});
  top: calc(50% - #{$progress-spinner-wd / 2});

  &--small {
    left: calc(50% - #{$progress-spinner-small-wd / 2});
    top: calc(50% - #{$progress-spinner-small-wd / 2});
  }

  &--search {
    left: calc(50% - #{$progress-spinner-small-wd / 2});
    top: -45px;
  }
}

.recentt-items {
  padding: 12px 6px 32px 6px;
  // 1
  .row {
    position: relative;

    &::after {
      @include size(100%, 5px);
      content: '';
      position: absolute;
      left: 0;
      bottom: 10px;
      background-color: $c-light;
    }
  }

  &__title {
    @include title-set;
  }

  &__title-showing {
    @include title-set-showing;
    margin-bottom: 20px;
    background: transparent !important;
  }
  &__title-showing-number {
    margin-left: 12px !important;
  }
  // recentt items compact
  &--compact {
    padding: 0 12px;
  }

  &--compact + &--compact {
    padding-top: 16px;
  }

  &--search-section {
    @media (xlarge-down) {
      padding-left: 36px;
    }
    @media ($max1400) {
      padding-left: 36px;
    }
    @media ($large-down) {
      padding-left: 54px;
    }
    @media ($small-down) {
      padding-left: 24px;
    }
  }
  
  // recentt items benefits
  &--benefits {
    position: relative;

    &::before {
      @include pseudo-gradient();
    }

    // 2
    .row::after {
      content: none;
    }
  }
}

// 2
.u-pseudo-gradient .recentt-items .row::after {
  content: none;
}
.u-text-truncate-multi {
  @include text-truncate-multiline(30px, 2);
  overflow: hidden;
  text-overflow: ellipsis;
}

//
// recentt item
//

.recentt-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;

  &__benefit {
    &:last-child {
      .benefit__content {
        border-bottom: none;
      }
    }

    &:nth-last-child(-n + 2) {
      .benefit__content {
        @media ($small-up) {
          border-bottom: none;
        }
      }
    }

    &:nth-last-child(-n + 3) {
      .benefit__content {
        @media ($large-up) {
          border-bottom: none;
        }
      }
    }
  }
  &__divider {
    &:last-child {
      .content__divider {
        border-bottom: none;
        padding: 0;
      }
    }
  }

  &__media {
    margin-right: 20px;
  }

  &__content {
    position: relative;
    width: 100%;
  }
  .benefit__content {
    padding: 16px 0px;
    border-bottom: 1px solid $divider-br-c;
  }

  .content__divider {
    padding-bottom: 12px;
    border-bottom: 1px solid $divider-br-c;
  }

  &__tags {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .tag + .tag {
      margin-left: 8px;
    }
  }

  &__caption.caption,
  &__overline.overline {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__subtitle-1.subtitle-1,
  &__subtitle-2.subtitle-2 {
    color: $c-dark-high-emphasis;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__subtitle-icon {
    vertical-align: bottom;
    margin-left: 4px;
  }

  &__body-2.body-2 {
    margin-top: 2px;
    margin-bottom: 0;
  }

  &__actions.mat-icon-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    color: rgba(0, 0, 0, 0.54);
  }

  &__paginator.mat-paginator {
    background-color: transparent;
  }

  // recentt item as link
  &--link {
    @include link-active;
  }

  // recentt item benefit
  &--benefit {
    padding-bottom: 0px;
    &::after {
      width: calc(100% - 40px - 8px);
    }

    .recentt-item__media {
      margin-right: 8px;
    }

    .recentt-item__content {
      width: calc(100% - 40px - 8px);
    }
  }

  // recentt item compact
  &--compact {
    padding-bottom: 12px;
    display: flex;
    align-items: flex-start;

    &::after {
      width: calc(100% - 24px - 24px);
    }

    .recentt-item__media {
      margin-right: 24px;
      color: rgba(0, 0, 0, 0.54);
    }

    .recentt-item__content {
      width: calc(100% - 24px - 24px);
    }

    .recentt-item__subtitle-2 {
      display: inline-block;
      margin-right: 8px;
    }

    .recentt-item__tags {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}
