// Image card
// -----------------------------------------------------------------------------
//
// 1. Using a pseudo-element to create the bottom shadow effect. Pseudo-element
//    is not render for browsers not supporting 'aspect-ratio', like Safari < 15,
//    to prevent a bug with the 'aspect-ratio' fallback.
//

@import '../_variables';
@import '../mixins/_aspect-ratio';
@import '../mixins/_link';
@import '~@hackandcraft/css-framework/scss/settings';
@import '~@hackandcraft/css-framework/scss/main';

.image-card {
  display: block;
  text-decoration: none;
  margin-bottom: 48px;

  @media ($medium-up) {
    margin-bottom: 56px;
  }

  &__thumb {
    @include aspect-ratio(440, 247);
    position: relative;
    margin-bottom: 8px;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform 0.25s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12), inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1);
    &::after {
      content: '';
      position: absolute;
      width: calc(100% + 8px);
      height: 12px;
      left: -4px;
      bottom: -12px;
      background-color: #fff;
      z-index: 1;
    }
  }

  &--industry-insight {
    border-radius: 12px 12px 0px 0px;
    position: relative;
    display: block;
    height: 220px;
    width: 100%;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12), inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12), inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;

    &--container {
      width: 100%;
      max-width: 425px;
      max-height: 220px;
      justify-content: center;
      align-items: center;
      position: relative;
      display: flex;
      &::after {
        content: '';
        position: absolute;
        width: calc(100% + 10px);
        height: 10px;
        bottom: -10px;
        background-color: #fff;
        z-index: 1;
      }
    }
  }

  &__header {
    margin-bottom: 14px;
  }

  &__footer {
    padding-left: 8px;
  }

  .subtitle-1 {
    margin-bottom: 2px;
  }

  .body-2 {
    @include text-truncate-multiline(18px, 3);
    margin-top: 0;
    margin-bottom: 0;
  }

  .mat-button {
    margin-left: -8px;
  }

  // Image card as link
  &--link {
    @include link-active;
  }

  &--elevated &__thumb {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
  }

  &--masked &__thumb {
    @include aspect-ratio(424, 220);
    border-radius: 12px 12px 0 0;
  }
}

.image-card--elevated.image-card--masked .image-card__thumb {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12), inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1);

  // 1
  @supports (aspect-ratio: 424 / 220) {
    &::after {
      content: '';
      position: absolute;
      width: calc(100% + 8px);
      height: 12px;
      left: -4px;
      bottom: -12px;
      background-color: $c-light;
      z-index: 1;
    }
  }
}
