// Link mixins
// -----------------------------------------------------------------------------
//
// 1. Rollover effect for `<a>` elements missing the `href` attribute.
//

@mixin link($c: $link-c) {
  font-weight: $link-fw;
  color: $c;
  text-decoration: $link-td;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $c-secondary;
    text-decoration: $link-hover-td;
    cursor: pointer; // 1
  }

  // &[disabled],
  // &.is-disabled {
  //   @include button-disabled($button-disabled-c, transparent);
  // }
}

@mixin link-active {
  text-decoration: none;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.96);
  }

  &:focus {
    outline: 0;
  }
}

@mixin link-role {
  text-decoration: none;
  color: inherit;

  // 1
  &:hover {
    cursor: pointer;
  }
}

@mixin link-secondary {
  font-weight: $fw-base;
  color: $c-secondary;
  text-decoration: $link-td;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $c-secondary;
    text-decoration: underline;
  }

  // &[disabled],
  // &.is-disabled {
  //   @include button-disabled($button-disabled-c, transparent);
  // }
}
