@import '../_variables';

.list-item {
    p {
        margin-left: 18.5px;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $divider-br-c;
        width: 100%;
    }

    a > p {
        margin-left: 0;
    }

    &:last-child {
        p {
            border-bottom: none;
        }
    }
}