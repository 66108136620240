// Aspect ratio mixins
// -----------------------------------------------------------------------------

@mixin aspect-ratio($wd, $ht) {
  aspect-ratio: #{$wd} / #{$ht};

  @supports not (aspect-ratio: $wd / $ht) {
    &::before {
      float: left;
      padding-top: ($ht / $wd) * 100%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
