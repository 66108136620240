// Editor's Pick
// -----------------------------------------------------------------------------
//
// 1. `20px` gutter between tiles, as designed.
//

@import '../_variables';

.editors-picks {
  position: relative;
  padding: 12px 0px;

  @media ($large-up) {
    padding-bottom: 32px;
  }
}

// Editor's Pick

.editors-pick {
  &__container {
    margin: 0 -10px !important;
  }

  &__item {
    padding: 0 10px !important;

    .media-object__content {
      padding-top: 6px !important;
    }

    &:last-child .media-object__content {
      @media ($small-down) {
        border-bottom: none;
      }
    }

    &:nth-child(2n+1):nth-last-child(-n+2),
    &:nth-child(2n+1):nth-last-child(-n+2) ~ & {
      .media-object__content {
        @media ($small-up) and ($medium-down) {
          border-bottom: none;
        }
      }
    }

    &:nth-child(3n+1):nth-last-child(-n+3),
    &:nth-child(3n+1):nth-last-child(-n+3) ~ & {
      .media-object__content {
        @media ($medium-up) and ($large-down) {
          border-bottom: none;
        }
      }
    }

    &:nth-child(4n+1):nth-last-child(-n+4),
    &:nth-child(4n+1):nth-last-child(-n+4) ~ & {
      .media-object__content {
        @media ($large-up) {
          border-bottom: none;
        }
      }
    }
  }
}
