// Latest insights
// -----------------------------------------------------------------------------

@import '../_variables';
@import '../mixins/_title';

.latest-insights {
  padding-top: 12px;

  &__title {
    @include title-set;
    width: 100%;
  }
  &__main-title {
    @media (max-width: 450px) {
      max-width: 200px;
    }
  }
  &__link {
    color: $c-primary-main;
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
  }

  &__container {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
    grid-gap: 1.75rem;

    @media (min-width: 960px) and (max-width: 1155px) {
      grid-template-columns: repeat(auto-fill, minmax(min(16rem, 100%), 1fr));
    }

    @media (min-width: 824px) and (max-width: 959px) {
      grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    }

    @media (min-width: 600px) and (max-width: 823px) {
      grid-template-columns: repeat(auto-fill, minmax(min(15rem, 100%), 1fr));
    }
  }
}
