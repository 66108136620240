// Media object
// -----------------------------------------------------------------------------
//
// 1. Using border rather than padding, in order to overlap portrait images.
//

@import '../_variables';
@import '../mixins/link';

.media-object {
  position: relative;
  display: flex;
  align-items: center;

  &__media {
    margin-right: 16px;
  }

  // &__content {
  //   //
  // }

  &__title {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__text,
  &__text.body-1,
  &__text.body-2,
  &__text.caption {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }

  // Align flex start
  &--align-flex-start {
    align-items: flex-start;
  }

  // Media object as link
  &--link {
    @include link-active;
  }

  // Colour
  &--colour {
    display: block;
    margin-bottom: 32px;

    @media ($large-up) {
      display: flex;
    }
  }

  &--colour &__media {
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px;

    @media ($large-up) {
      width: $colour-swatch-wd-xlarge;
      margin-right: 64px;
      margin-bottom: 0;
    }
  }

  &--colour &__content {
    width: 100%;

    @media ($large-up) {
      width: calc(100% - #{$colour-swatch-wd-xlarge} - 64px);
    }
  }

  &--colour &__inner {
    width: 100%;
    margin-bottom: 16px;

    @media ($xlarge-up) {
      width: 672px;
    }
  }

  // Compact
  &--compact &__media {
    margin-right: 12px;
  }

  // Organisation
  &--org {
    width: calc(100% - 24px);
  }

  &--org &__media {
    margin-right: 12px;
  }

  // With border
  &--border {
    margin-bottom: 10px;
  }

  &--border &__content {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $divider-br-c;
  }

  // No logo
  &--no-logo &__content {
    width: 100%;
  }
}
