@import "../_variables";
@import "~@hackandcraft/css-framework/scss/settings";
@import "~@hackandcraft/css-framework/scss/main.scss";

@mixin _footer-divider {
  content: "";
  position: absolute;
  top: 0;
  left: 24px;
  width: calc(100% - 48px);
  height: 1px;
  background-color: $c-dark-lower-emphasis;
}

:host {
  flex: none;
}

// All rows
.container {
  position: relative;
  font-family: $ff-base;
  font-size: 13.8px;
  font-weight: $fw-base;
  line-height: 1.45;
  letter-spacing: 0.25px;
  color: $c-dark-medium-emphasis;
  margin: 0;
  padding: 0 24px !important;
  max-width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer {
  position: relative;
  background-color: transparent; // 1
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;

  @media ($large-up) {
    height: $footer-ht;
  }

  // Row 1
  &__row-1 {
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media ($large-up) {
      height: $footer-row-1-ht;

      a:first-child {
        margin-left: 0;
      }

      a:last-child {
        margin-right: 0;
      }
    }
  }

  &__brand {
    @include size(144px, 32px);
    margin: 20px auto;

    @media ($large-up) {
      margin: 30 auto;
    }
  }

  // Row 2
  &__row-2 {
    width: 100%;

    &::after {
      @include _footer-divider;
    }
  }

  nav {
    width: 100%;
  }

  &__legal {
    @include list-reset;
    padding: 11px 0;

    > li {
      padding: 11px 0;

      @media ($medium-up) {
        margin-right: 32px;
      }

      > a {
        text-decoration: none;
      }
    }
    @media ($medium-up) {
      display: flex;
    }
  }

  // Row 3
  &__row-3 {
    display: flex;
    justify-content: space-between;
    height: $footer-row-3-ht;

    &::after {
      @include _footer-divider;
    }
  }

  &__copyright {
    display: block;
    margin-bottom: 0;
  }

  &__logo {
    @include size(72px, 13px);
    margin-left: 32px;
  }
}
