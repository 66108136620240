// Masked image
// -----------------------------------------------------------------------------
//
// 0. Fallback colour, in case no image is retrieved.
// 1. Use inline styles instead, for dynamic images coming from CMS.
//

@import '../_variables';
@import '~@hackandcraft/css-framework/scss/settings';
@import '~@hackandcraft/css-framework/scss/main.scss';

$_masked-image-sp: 16px;

$_masked-image-inner-wd: 328px;
$_masked-image-inner-ht: 164px;
$_masked-image-wd: floor(
  $_masked-image-inner-wd + $_masked-image-sp + $_masked-image-sp
);

$_masked-image-inner-wd-medium: 384px;
$_masked-image-inner-ht-medium: 192px;
$_masked-image-wd-medium: floor(
  $_masked-image-inner-wd-medium + $_masked-image-sp + $_masked-image-sp
);

.masked-image {
  padding-bottom: 66px;

  &__title {
    margin-bottom: 8px;

    .subtitle-1 {
      margin-bottom: 0;
    }

    .heading6,
    .body-2 {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: block;
    width: $_masked-image-wd;
    padding: 15.65px 7px 10px 8px;
    overflow: hidden;
    background-color: $c-light;

    @media ($medium-up) {
      width: $_masked-image-wd-medium;
    }
  }

  &__img {
    @include size($_masked-image-inner-wd, $_masked-image-inner-ht);
    position: relative;
    display: block;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    background-color: $c-light; // 0
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;

    @media ($medium-up) {
      @include size(
        $_masked-image-inner-wd-medium,
        $_masked-image-inner-ht-medium
      );
    }

    &--1 {
      background-image: url($img-path + 'academy/dulux-academy-prospectus@2x.png'); // 1
    }

    &--2 {
      background-image: url($img-path + 'academy/citb-info@2x.png'); // 1
    }

    &::after {
      content: '';
      position: absolute;
      width: $_masked-image-wd;
      height: $_masked-image-sp;
      left: (-$_masked-image-sp);
      bottom: (-$_masked-image-sp);
      box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.1);
      background-color: $c-light;
      z-index: 1;

      @media ($medium-up) {
        width: $_masked-image-wd-medium;
      }
    }
  }
}
