@import '~@hackandcraft/css-framework/scss/settings';
//@import '~@hackandcraft/css-framework/scss/main.scss';

$screen-xs-min: 360px;
$screen-sm-min: 768px;
$screen-md-min: 1024px;
$screen-lg-min: 1280;
$screen-xl-min: 1440px;

$grid-gutter-width: 32px;

$xsmall-up: 'min-width: 360px';
$small-up: 'min-width: 768px';
$medium-up: 'min-width: 1024px';
$large-up: 'min-width: 1280px';
$xlarge-up: 'min-width: 1440px';

$xsmall-down: 'max-width: 360px';
$small-down: 'max-width: 768px';
$medium-down: 'max-width: 1024px';
$large-down: 'max-width: 1280px';
$xlarge-down: 'max-width: 1440px';
$max1400: 'max-width: 1400px';

$c-category-0: #c2a468;
$c-category-1: #00aeea;
$c-category-2: #f47920;
$c-category-3: #4b5eaa;
$c-primary-main: rgba(1, 33, 105, 1); //theme palette primary main

$assets-path: '/assets/';
$img-path: $assets-path + 'img/';

$gradient-light-to-gray: linear-gradient(to bottom, $c-light, #f7f7f7);

$divider-br-c: rgba(0, 0, 0, 0.08);

// $colour-swatch-wd-small: 16px;
$colour-swatch-wd-medium: 44px;
// $colour-swatch-wd-large: 160px;
$colour-swatch-wd-xlarge: 320px;

$progress-spinner-wd: 48px;
$progress-spinner-small-wd: 24px;
$progress-spinner-c: $c-primary;

//
// Footer
//
$footer-pdt: 32px;
$footer-row-1-ht: 92px;
$footer-row-2-ht: 68px;
$footer-row-3-ht: 68px;
$footer-ht: floor($footer-pdt + $footer-row-1-ht + $footer-row-2-ht + $footer-row-3-ht);
