@import '../variables';

@mixin retina-background-images(
  $name,
  $ext: 'png',
  $repeat: no-repeat,
  $pos: 0 0,
  $size: cover,
  $bgc: gray
) {
  $at1x_path: '#{$img-path}#{$name}.#{$ext}';
  $at2x_path: '#{$img-path}#{$name}@2x.#{$ext}';
  $at3x_path: '#{$img-path}#{$name}@3x.#{$ext}';

  background-image: url('#{$at1x_path}');
  background-repeat: $repeat;
  background-position: $pos;
  background-size: $size;
  background-color: $bgc;

  // @2x Images (Pixel Ratio of 1.25+)
  @media only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min--moz-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx) {
    background-image: url('#{$at2x_path}');
  }

  // @3x Images (Pixel Ratio of 2.25+)
  @media only screen and (-o-min-device-pixel-ratio: 9/4),
    only screen and (-webkit-min-device-pixel-ratio: 2.25),
    only screen and (min--moz-device-pixel-ratio: 2.25),
    only screen and (min-device-pixel-ratio: 2.25),
    only screen and (min-resolution: 2.25dppx) {
    background-image: url('#{$at3x_path}');
  }
}