// Pseudo gradient and background color
// -----------------------------------------------------------------------------

@mixin pseudo-area {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: -1;
}

@mixin pseudo-gradient {
  @include pseudo-area;
  background-image: $gradient-light-to-gray;
}

@mixin pseudo-bgc($bgc) {
  @include pseudo-area;
  background-color: $bgc;
}
