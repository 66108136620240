// Thumbnail
// -----------------------------------------------------------------------------
//
// 1. Inner padding for the image to not touch the borders.
//
@import '~@hackandcraft/css-framework/scss/settings';
@import '~@hackandcraft/css-framework/scss/main.scss';

$_thumb-brc: rgba(0, 0, 0, 0.05);
$_thumb-bgc: #fafafa;
$_thumb-bsw: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

.thumb {
  // Size
  &--4xsmall {
    @include size(24px, 24px);
  }

  &--3xsmall {
    @include size(32px, 32px);
  }

  &--2xsmall {
    @include size(40px, 40px);
  }

  &--xsmall {
    @include size(48px, 48px);
  }

  &--small {
    @include size(64px, 64px);
  }

  &--medium {
    @include size(96px, 96px);
  }

  &--large {
    @include size(112px, 112px);
  }

  &--xlarge {
    @include size(160px, 160px);
  }

  &--2xlarge {
    @include size(320px, 320px);
  }

  // Type
  &--article {
    padding: 18px; // 1
    border-radius: 4px;
    background-color: $_thumb-bgc;
    box-shadow: $_thumb-bsw;
  }

  &--insight {
    border-radius: 4px;
    box-shadow: $_thumb-bsw;
    background-color: $_thumb-bgc;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
  }

  &--notification {
    @include size(auto, 64px);
    display: block;
    max-width: 100%;
    padding: 4px; // 1
    border-radius: 4px;
    border: 1px solid $divider-br-c;
    background-color: $_thumb-bgc;
  }

  &--org {
    border-radius: 4px;
    border: 1px solid $_thumb-brc;
    background-color: $_thumb-bgc;
  }

  &--picture {
    padding: 8px; // 1
    border-radius: 4px;
    background-color: $_thumb-bgc;
    box-shadow: $_thumb-bsw;
  }

  &--picture-icon {
    padding: 28px; // 1
    border-radius: 4px;
    background-color: $c-light;
    box-shadow: $_thumb-bsw;
  }

  &--picture-plain {
    padding: 8px; // 1
    border-radius: 4px;
    border: 1px solid $_thumb-brc;
    background-color: $_thumb-bgc;
  }

  &--swatch {
    border-top-right-radius: 17px;
    border-bottom-left-radius: 17px;
    background-color: $_thumb-bgc;
    box-shadow: $_thumb-bsw;
  }

  &--swatch-plain {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid $_thumb-brc;
    background-color: $_thumb-bgc;
  }
}
