.policy-page {
  ul {
    margin-bottom: 0;
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 32px;
      margin-bottom: 8px;

      &::before {
        left: 0;
        background: rgba(0, 0, 0, 0.54) no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: "";
        font-weight: 600;
        font-size: inherit;
        position: absolute;
        top: 0.5rem;
        width: 10px;
        height: 10px;
      }
    }
  }

  ol {
    padding: 0;
    counter-reset: item;
    list-style: none;

    > li {
      position: relative;
      padding-left: 32px;
      margin-bottom: 8px;

      &:before {
        counter-increment: item;
        content: counter(item)". ";
        position: absolute;
        left: 0;
      }
    }

    > ul > li {
      padding-left: 64px;

      &:before {
        left: 32px;
      }
    }
  }

  a {
    color: rgba(0, 0, 0, 0.6);;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  td {
    border-bottom: 1px solid #e1e1e1;
    padding: 7px;
    vertical-align: top;
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}
