@import '../variables';
// Grid
// -----------------------------------------------------------------------------

// $_screen-md-max: $screen-md-min - 1;
// $_medium-down: 'max-width:#{$_screen-md-max}';


.container {
  // // from 0 to 599px
  // @media ($_medium-down) {
  //   max-width: none;
  // }
  width: 100%;
  max-width: $screen-xl-min - $grid-gutter-width;
}

.row--small {
  margin-right: -8px;
  margin-left: -8px;

  .col-sm-12,
  .col-md-6,
  .col-lg-3,
  .col-lg-4 {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.row--medium {
  margin-right: -10px;
  margin-left: -10px;

  .col-sm-12,
  .col-md-6,
  .col-lg-3,
  .col-lg-4 {
    padding-right: 10px;
    padding-left: 10px;
  }
}
