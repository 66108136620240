@import '../variables';

// Fonts
$_ff-primary: 'Open Sans', 'Colour Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$_ff-secondary: 'Colour Sans Bold', 'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$_ff-base: $_ff-primary;

.rte {
  h2 {
    font-family: $_ff-base;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: rgba(0, 0, 0, 0.6);
  }

  h3 {
    font-family: $_ff-base;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
  }

  h5 {
    font-family: $_ff-base;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin: 32px 0 8px;
    color: rgba(0, 0, 0, 0.87);
  }

  h6 {
    font-family: $_ff-base;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin: 32px 0 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  p {
    color: rgba(0, 0, 0, 0.54);
  }

  ul {
    list-style-image: url('/assets/img/Check.svg');

    li {
      border-bottom: 1px solid $divider-br-c;
      padding: 16px 0;
    }

    li:last-child {
      border-bottom: none;
    }
  }
}

.listing-card-rte {
  * {
      display: inline;
  }
}