// Banner
// -----------------------------------------------------------------------------

@import '../_variables';

@mixin _banner-bg($bgc) {
  background-repeat: no-repeat;
  background-position: top left, top right, 0 0;
  background-size: auto, auto, cover;
  background-color: $bgc;
}

.banner {
  min-height: 176px;
  padding: 20px 24px;
  margin-bottom: 32px;
  border-radius: 4px;
  background-color: $c-dark-disabled-emphasis;

  @media ($large-up) {
    display: flex;
    align-items: flex-end;
  }

  &__content {
    @media ($large-up) {
      width: 50%;
    }
  }

  &__actions {
    text-align: right;

    @media ($large-up) {
      width: 50%;
    }

    .mat-icon-button {
      position: relative;
      right: -8px;
      color: rgba(255, 255, 255, 0.96);
    }

    .se_icon {
      color: rgba(255, 255, 255, 0.96);
    }
  }

  // Training - TODO : create a mixin for retina images
  &--0 {
    @include _banner-bg($c-category-0);
    background-image: url('/assets/img/banner/left-light.png'),
      url('/assets/img/banner/right-light.png'),
      url('/assets/img/banner/training.png');
    background-blend-mode: soft-light, soft-light, normal;
  }

  // Think Forward - TODO : create a mixin for retina images
  &--1 {
    @include _banner-bg($c-category-1);
    background-image: url('/assets/img/banner/left-light.png'),
      url('/assets/img/banner/right-light.png'),
      url('/assets/img/banner/think-forward.png');
    background-blend-mode: soft-light, soft-light, normal;
  }

  // Work Smarter - TODO : create a mixin for retina images
  &--2 {
    @include _banner-bg($c-category-2);
    background-image: url('/assets/img/banner/left-light.png'),
      url('/assets/img/banner/right-light.png'),
      url('/assets/img/banner/work-smarter.png');
    background-blend-mode: soft-light, soft-light, normal;
  }

  // Rewards - TODO : create a mixin for retina images
  &--3 {
    @include _banner-bg($c-category-3);
    background-image: url('/assets/img/banner/left-light.png'),
      url('/assets/img/banner/right-light.png'),
      url('/assets/img/banner/rewards.png');
    background-blend-mode: soft-light, soft-light, normal;
  }
}
