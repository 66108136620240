@import '../_variables';
@import '../mixins/image';
@import '../mixins/link';

.categories {
  position: relative;
  padding: 12px 0px;

  @media ($large-up) {
    padding-bottom: 32px;
  }

  // 1
}

// Category
.category {
  display: block;
  align-self: center;
  position: relative; // 2
  height: 0; // 2
  padding-top: (186/325) * 100%; // 2
  overflow: hidden; // 2
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  margin-bottom: 20px;

  @media ($medium-up) {
    border-radius: 12px;
  }

  @media ($large-up) {
    padding-top: (64/325) * 100%; // 2
    border-radius: 16px;
    margin-bottom: 0;
  }

  // 2
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // 2
  &__inner-centering {
    height: 100%;
    display: flex;
    align-items: center;
    user-select: none;
  }

  // 2
  &__inner-sizing {
    padding-left: 12px;
  }

  &__inner-header {
    @media (max-width: 768px) {
      font-size: 1rem !important;
    }
  }

  &__container {
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
  // Recognition
  &--training {
    @include retina-background-images(
      $name: 'training',
      $bgc: $c-category-0
    );
  }

  // Think Forward
  &--thinkforward {
    @include retina-background-images(
      $name: 'think_forward',
      $bgc: $c-category-1
    );
  }

  // Work Smarter
  &--worksmarter {
    @include retina-background-images(
      $name: 'work_smarter',
      $bgc: $c-category-2
    );
  }

  // Rewards
  &--rewards{
    @include retina-background-images(
      $name: 'rewards',
      $bgc: $c-category-3
    );
  }

  // Category as link
  &--link {
    @include link-active;
  }
}